<template>
  <div class="row mx-0 justify-content-center">
    <ReviewDonation v-model="value" v-model:acceptTerms="acceptTermsValue"  :sadaqahUserDonatingType="sadaqahUserDonatingType" @goTo="goTo" />
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'Wizard 2',
  props: ['modelValue', 'sadaqahUserDonatingType', 'acceptTerms'],
  emits: ['update:modelValue', 'update:acceptTerms', 'goTo'],
  components: {
    ReviewDonation: defineAsyncComponent(() => import('./views/reviewdonation/ReviewDonation.vue'))
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    acceptTermsValue: {
      get () {
        return this.acceptTerms
      },
      set (val) {
        this.$emit('update:acceptTerms', val)
      }
    }
  },
  methods: {
    goTo (val) {
      this.$emit('goTo', val)
    }
  }
}
</script>
